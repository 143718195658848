import React from 'react'
import bytal from '../bytal.png'
import memegen from '../memegen.png'
import todolistsite from '../todolistsite.png'

const Projects = () => {
    return(
       <section className='projects'>
        <h3>Projects</h3>
         <div className='containerr'>
            <p>Simple Game Installation Website </p>
           <img src={bytal} width={300} />
           <p>Download type: Torrent</p>
           <button><a href='https://bytal2.pages.dev'>Visit</a></button>
         </div>

         <div className='containerr'>
         <p>Meme Generator Website</p>
           <img src={memegen} width={300} />
           <p>Meme API based app</p>
           <button><a href='https://lextermemes.pages.dev'>Visit</a></button>
           </div>


           <div className='containerr'>
           <p>TodoList Website</p>
           <img src={todolistsite} width={300} />
           <p>Keep todo lists, add and delete</p>
           <button><a href='https://lextertodo.pages.dev'>Visit</a></button>
           </div>

       </section>
    )
}

export default Projects;