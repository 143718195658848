import React from 'react';
import {Routes, Route , Link} from 'react-router-dom'
import { useReducer } from 'react';
import Home from './components/Home'
import AboutMe from './components/AboutMe'
import Projects from './components/Projects'

function App() {
  return (
 <div>
<nav className="navbar">
  <div className="container">
    <div className="navbar-links" id="navbarLinks">
      <Link to="/home">Home</Link>
      <Link to="/about-me">AboutMe</Link>
      <Link to="/projects">Projects</Link>
    </div>
  </div>
</nav>


 <Routes>
  <Route path="/home"  element={<Home/>}/>
  <Route path="/about-me"  element={<AboutMe/>}/>
  <Route path="/projects"  element={<Projects/>}/>
 </Routes>
</div>

  );
}

export default App