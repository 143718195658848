import React from 'react'
import { useState, useEffect } from 'react';

const AboutMe = () =>  { 
    let age = 16;
    let date = new Date();
    let currentDate = date.getDate();
    let currentMonth = date.getMonth() + 1; // JavaScript months are zero-indexed, so January is 0, February is 1, etc.
    
    // Check if the current date is the 21st of October
    if (currentDate === 21 && currentMonth === 10) { // October is represented as 10
        age += 1; // Increment age by 1
    }
    
    console.log(age); // Output the updated age
    
    
    return(
    <section className='About'>
        <h3>Myself</h3>
        <div className='Area'>
            <p>I'm Alex Nyanteng</p>
            <p>Born on October 21, 2007 ( {age} )</p>
            <p>Frontend Web Developer Specialized in React</p>
            <p>Marital Status: Single</p>
        </div>
        <div className='Area'>
            <p>Currently a student at St. Hubert Seminary SHS</p>
            <p>Reading General Science with Physics, Further Mathematics , Chemistry and Biology</p>
            <p>Yet to complete High School in 2025 to study computer science at the University</p>
        </div>
        <div className='Area'>
        <p>Started programming at 13 years old</p>
        <p>Gained basic computer usage knowledge at young age</p>
        <p>I helped my teachers back in school in technical aspects such as making student report cards, recording marks in excel  etc..</p>
        <p>Manages a Youtube channel for coding enthusiasts as a way of sharing my skills</p>
        </div>
        <div className='Area'>
            <p>Good with Office Productivity Tools</p>
            <p>A bit experience in Graphic Design ( I used them for my video thumbnails on Youtube )</p>
            <p>Video Editing ( Basic )</p>
            <p>Cyber Forensics ( Stegnalasis etc. )</p>
            <p>Basic Knowlegde of Ethical Hacking ( Pen Testing )</p>
            <p>Web Design with HTML , CSS, boostrap and templates</p>
            <p>Computer Programming  ( JavaScript, Python for web dev )</p>
        </div>
        <div className='Area'>
            <p>Completed Basic Education at Holy Cross R/C JHS (BECE)</p>
            <p>Competed in Cyber Champions Competion ( By CyberGhana )</p>
            <p>150 subscribers on Youtuber</p>
            <p>Created my first site at age 13</p>
            <p>More to come</p>
        </div>
        <div className='Area'>
            <p>Am a tech enthusiast</p>
            <p>I like playing video games</p>
            <p>Music Lover</p>
        </div>
    </section>
    )
}

export default AboutMe;